import React from "react";
import { injectIntl } from "gatsby-plugin-intl";

import SocialTopNetwork from '../components/layout/header/social-top-network';
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons'

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Banner from "../components/banner";
import Section from "../components/section";

import "../styles/team.less";

const Team = (props) => {

    const translate = (text) => props.intl.formatMessage({id: text});
  return <Layout>
    <SEO title={translate("Team")} description="" location={props.location} />
    
    <Banner 
            className="banner team" 
            filename="bg_demo_day.jpg" 
            title={ translate("Who are we?")}
            subtitle=""
        />

    <Section className="team-panel">
        <p className="subtitle">Adventurees.com es una Plataforma de Financiación Participativa autorizada e inscrita en 2017 en la Comisión Nacional del Mercado de Valores para poder canalizar inversión, tanto en valores (acciones y otros valores representativos del capital) como en préstamos, hacia negocios en crecimiento. Basa su modelo de análisis y selección de startups en la Metodología Goldsmith ®, desarrollada por el business angel norteamericano Randy Goldsmith, co-fundador de la empresa. El Modelo Goldsmith ® ha sido reconocido como “Best Practice” por el “Internacional Business Incubator Association” y ha ayudado a miles de negocios a crecer de forma robusta y financiarse adecuadamente. Adventurees.com es una empresa promovida desde el <a href="https://www.adventurees.com" target="_blank">Adventurees</a>, entidad que ayuda a numerosas organizaciones y ecosistemas de emprendimiento alrededor del mundo a desarrollar y financiar negocios.</p>
        <h2 className="title">{translate("Our team")}</h2>  
        
          <div className="team-members row">
              <div className="member">
                  <div className="card">
                      <img className="card-img-top" src="https://www.adventurees.com/images/team/yael-oaknin.jpg" alt="Yael Oaknin" />
                      <div className="card-body">
                          <h3 className="card-title">Yael H. Oaknín</h3>
                          <span clas="card-text">{translate("CEO")}</span>
                          <SocialTopNetwork className="linkedin-link" icon={faLinkedinIn} link="https://www.linkedin.com/in/yaelhoaknin/" network="linkedin" />
                      </div>
                  </div>
              </div>
              <div className="member">
                <div className="card">
                    <img className="card-img-top" src="https://www.adventurees.com/images/team/jose-franco.jpg" alt="Jose M.Franco"/>
                    <div className="card-body">
                        <h3 className="card-title">José M. Franco</h3>
                        <span clas="card-text">{translate("CEO")}</span>
                        <SocialTopNetwork className="linkedin-link" icon={faLinkedinIn} link="https://www.linkedin.com/in/josefrancoperez/" network="linkedin" />
                    </div>
                </div>
            </div>
            <div className="member">
                <div className="card">
                    <img className="card-img-top" src="https://www.adventurees.com/images/team/guillermo-prieto.jpg" alt="Guillermo Prieto"/>
                    <div className="card-body">
                        <h3 className="card-title">Guillermo Prieto</h3>
                        <span clas="card-text">{translate("Marketing area")}</span>
                        <SocialTopNetwork className="linkedin-link" icon={faLinkedinIn} link="https://www.linkedin.com/in/guillermoprietoalonso/" network="linkedin" />
                    </div>
                </div>
            </div>
            { /*
            <div className="member">
                <div className="card">
                    <img className="card-img-top" src="https://www.adventurees.com/images/team/nicolas-zumarraga.jpg" alt="Nicolás Zumárraga"/>
                    <div className="card-body">
                        <h3 className="card-title">Nicolás Zumárraga</h3>
                        <span clas="card-text">{translate("Marketing area")}</span>
                        <SocialTopNetwork className="linkedin-link" icon={faLinkedinIn} link="https://www.linkedin.com/company/adventurees/" network="linkedin" />

                    </div>
                </div>
            </div>
            */ }
            <div className="member">
                <div className="card">
                    <img className="card-img-top" src="https://www.adventurees.com/images/team/javier-coca.jpg" alt="Javier de Coca"/>
                    <div className="card-body">
                        <h3 className="card-title">Javier de Coca</h3>
                        <span clas="card-text">{translate("Marketing and contents")}</span>
                        <SocialTopNetwork className="linkedin-link" icon={faLinkedinIn} link="https://www.linkedin.com/in/javicoca/" network="linkedin" />
                    </div>
                </div>
            </div>

            <div className="member">
                <div className="card">
                    <img className="card-img-top" src="https://www.adventurees.com/images/team/eva-martin.jpg" alt="Eva Mart&iacute;n"/>
                    <div className="card-body">
                        <h3 className="card-title">Eva Martín</h3>
                        <span clas="card-text">{translate("Legal and administrative area")}</span>
                        <SocialTopNetwork className="linkedin-link" icon={faLinkedinIn} link="https://www.linkedin.com/in/evamariamartincruz/" network="linkedin" />
                    </div>
                </div>
            </div>

            { /*
            <div className="member">
                <div className="card">
                    <img className="card-img-top" src="https://www.adventurees.com/images/team/jorge-violan.jpg" alt="Jorge Violan"/>
                    <div className="card-body">
                        <h3 className="card-title">Jorge Violán</h3>
                        <span clas="card-text">{translate("Fiscal and accounting area")}</span>
                        <SocialTopNetwork className="linkedin-link" icon={faLinkedinIn} link="https://www.linkedin.com/in/jorge-viol%C3%A1n-acevedo-3699304a/" network="linkedin" />
                    </div>
                </div>
            </div>
            <div className="member">
                <div className="card">
                    <img className="card-img-top" src="https://www.adventurees.com/images/no_user_pic.png" alt="Telmo Pérez"/>
                    <div className="card-body">
                        <h3 className="card-title">Telmo Pérez</h3>
                        <span clas="card-text">{translate("Marketing area")}</span>
                        <SocialTopNetwork className="linkedin-link" icon={faLinkedinIn} link="https://www.linkedin.com/in/telmopdv/" network="linkedin" />
                    </div>
                </div>
            </div>
            */ }
            <div className="member">
                <div className="card">
                    <img className="card-img-top" src="https://www.adventurees.com/images/team/francisco-mateo.jpg" alt="Francisco Mateo"/>
                    <div className="card-body">
                        <h3 className="card-title">Francisco Mateo</h3>
                        <span clas="card-text">{translate("CTO")}</span>
                        <SocialTopNetwork className="linkedin-link" icon={faLinkedinIn} link="https://www.linkedin.com/in/francisco-javier-mateo-ariza-20a0475b" network="linkedin" />
                    </div>
                </div>
            </div>
          </div>

    </Section>

    <Section title={ translate("Board of advisors")} className="director-panel">
    <div className="board-of-directors row justify-content-center">
            <div className="director">
                <div className="avatar">
                    <img src="https://www.adventurees.com/images/team/randy-goldsmith.jpg" alt="Randy Goldsmith"/>
                </div>
                <div className="member-description">
                    <h3>Randy Goldsmith</h3>
                    <span>{translate("CEO")}</span>
                </div>
            </div>
            <div className="director">
                <div className="avatar">
                    <img src="https://www.adventurees.com/images/team/pedro-anton.jpg" alt="Pedro Antón"/>
                </div>
                <div className="member-description">
                    <h3>Pedro Antón</h3>
                    <span>{translate("Business angel and consultant")}</span>
                </div>
            </div>
            <div className="director">
                <div className="avatar">
                    <img src="https://www.adventurees.com/images/team/nacho-loscos.jpg" alt="Nacho Loscos"/>
                </div>
                <div className="member-description">
                    <h3>Nacho Loscos</h3>
                    <span>{translate("Expert in digital strategy")}</span>
                </div>
            </div>
            <div className="director">
                <div className="avatar">
                    <img src="https://www.adventurees.com/images/team/fernando-moncada.jpg" alt="Fernando Moncada"/>
                </div>
                <div className="member-description">
                    <h3>Fernando Moncada</h3>
                    <span>{translate("Business angel")}</span>
                </div>
            </div>
            <div className="director">
                <div className="avatar">
                    <img src="https://www.adventurees.com/images/team/luis-trujillo.jpg" alt="Luis Trujillo"/>
                </div>
                <div className="member-description">
                    <h3>Luis Trujillo</h3>
                    <span>{translate("Lawyer")}</span>
                </div>
            </div>
            <div className="director">
                <div className="avatar">
                    <img src="https://www.adventurees.com/images/team/mery-oaknin.jpg" alt="Mery Oaknín"/>
                </div>
                <div className="member-description">
                    <h3>Mery Oaknín</h3>
                    <span>{translate("Businesswoman and auditor")}</span>
                </div>
            </div>
        </div>
    </Section>

  </Layout>
}

export default injectIntl(Team);